import React from "react";
import "./Footer.css";
import { ReactComponent as Git } from "../icons/github.svg";
import { ReactComponent as Ig } from "../icons/ig.svg";
import { ReactComponent as Linkedin } from "../icons/linkedin.svg";
import { ReactComponent as Mail } from "../icons/mail.svg";
import logo from "./bday1.jpg"; // Ensure you have a logo image in your project

function Footer() {
  return (
    <footer className="footer" id="contact">
      <div className="footer-content">
        <img src={logo} alt="Logo" className="footer-logo" />
        <div className="footer-links">
          <a
            href="https://github.com/Gurjot108"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Git className="footer-icon" />
          </a>
          <a
            href="https://www.instagram.com/gurgogh"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Ig className="footer-icon" />
          </a>
          <a
            href="https://www.linkedin.com/in/gurjot-singh-2043532a8/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Linkedin className="footer-icon" />
          </a>
          <a href="mailto:gurjotsingh810@gmail.com">
            <Mail className="footer-icon" />
          </a>
        </div>
      </div>
      <hr className="footer-line" />
      <div className="footer-credit">Made with 🤍by Gurgogh</div>
    </footer>
  );
}

export default Footer;
