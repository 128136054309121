import React from "react";
import "./Aboutme.css"; // Import your CSS file

// Use Cloudinary URLs for videos
const amVideoURL =
  "https://res.cloudinary.com/dym51lhbj/video/upload/v1723966795/am_qscfjs.mp4";
const skVideoURL =
  "https://res.cloudinary.com/dym51lhbj/video/upload/v1723966797/sk_iorsjw.mp4";
const sunVideoURL =
  "https://res.cloudinary.com/dym51lhbj/video/upload/v1723966796/sun_w7nwha.mp4";

function Aboutme() {
  const content = [
    {
      title: "Who am I",
      description:
        "I haven't yet fully discovered who I am. It's a confusing and introspective journey, trying to understand my true self. Meanwhile, others seem to have an image of me. They describe me as someone who is fashionable, has a passion for philosophy,art and music, and possesses some skills in video editing.But I am not that image.",
      video: amVideoURL,
    },
    {
      title: "Technical Expertise",
      description:
        "A passionate web developer with a keen interest in creating dynamic and user-centric applications. With a solid foundation in the MERN stack (MongoDB, Express.js, React, and Node.js), I specialize in building responsive and efficient web solutions. My journey into development has been driven by a desire to solve real-world problems and deliver seamless digital experiences.",
      video: skVideoURL,
      reverse: true,
      rightAlignTitle: true,
    },
    {
      title: "Current Focus",
      description:
        "At present, my primary focus is on advancing my expertise in DevOps. I am committed to mastering the principles and tools that underpin efficient and reliable software delivery. Concurrently, I am honing my skills in video editing, recognizing that creativity is an endless pursuit. Occasionally, I delve into philosophical texts, seeking to gain insights into the nature of humanity and the world we inhabit.",
      video: sunVideoURL,
    },
  ];

  return (
    <div className="outer-container" id="about-me">
      <h1 className="hclass">About Me</h1>
      <div className="aboutme-container">
        {content.map((item, index) => (
          <div key={index} className="section">
            <div className={`section-content ${item.reverse ? "reverse" : ""}`}>
              <div className="text-container">
                <h2 className={item.rightAlignTitle ? "nd" : ""}>
                  {item.title}
                </h2>
                <p>{item.description}</p>
              </div>
              <div className="video-container">
                <video autoPlay loop muted className="video">
                  <source src={item.video} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Aboutme;
