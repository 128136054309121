import React, { useEffect, useState } from "react";
import "./Home.css";

function Home() {
  const lines = [
    "Hi",
    "Welcome to my Portfolio",
    "I'm a Developer",
    "An Editor",
    "Philosophy Lover",
    "Let's connect",
    "Build together",
  ];
  const [text, setText] = useState(lines[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setText((prevText) => {
        const currentIndex = lines.indexOf(prevText);
        const nextIndex = (currentIndex + 1) % lines.length;
        return lines[nextIndex];
      });
    }, 6000); // Change text every 6 seconds (matches the animation duration)

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="home-container" id="home">
      <div className="head-container">
        <p key={text} className="thehead text-fade-up">
          {text}
        </p>
      </div>
    </div>
  );
}

export default Home;
