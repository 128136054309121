import React from "react";
import "./App.css"; // Import global styles
import Home from "./components/Home";
import AboutMe from "./components/Aboutme";
import Footer from "./components/Footer";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Nav from "./components/Nav";

// Use Cloudinary URL for video background
const videoBackgroundURL =
  "https://res.cloudinary.com/dym51lhbj/video/upload/v1723966805/bg_tvj6sf.mp4";

function App() {
  return (
    <div>
      <div className="app-container">
        <video autoPlay loop muted className="video-background">
          <source src={videoBackgroundURL} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="content-container">
          <Nav />
          <Home />
          <AboutMe />
          <Skills />
          <Projects />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default App;
