import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import "./Nav.css";

function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;
      if (currentScrollY > lastScrollY) {
        setScrollDirection("down");
      } else {
        setScrollDirection("up");
      }
      lastScrollY = currentScrollY > 0 ? currentScrollY : 0;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navItems = [
    { name: "Home", id: "home" },
    { name: "About me", id: "about-me" },
    { name: "Skills", id: "skills" },
    { name: "Projects", id: "projects" },
    { name: "Contact", id: "contact" },
  ];

  return (
    <nav className={`main-nav ${scrollDirection === "down" ? "hide" : ""}`}>
      <button className="hamburger" onClick={toggleMenu}>
        {isOpen ? "▲" : "☰"}
      </button>
      <ul className={`nav-items ${isOpen ? "open" : ""}`}>
        {navItems.map((item, index) => (
          <li key={index}>
            <Link
              to={item.id}
              smooth={true}
              duration={500}
              onClick={() => setIsOpen(false)}
            >
              <button>{item.name}</button>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Nav;
