import "./Skills.css";

function Skills() {
  const skills = [
    {
      title: "Programming Languages",
      description: "C | C++ | Python | Javascript | HTML | CSS | Bash | SQL.",
    },
    {
      title: "Data Structures and Algorithms",
      description:
        "Arrays | Strings | Linked List | Stack | Queue | Binary Trees, AVL Trees | Graphs | DP | Trie",
    },
    {
      title: "Frameworks and Technologies",
      description:
        "React | Next.js | Node.js | Express.js | MongoDB | Tailwind | Jupyter Notebook | Git | GitHub.",
    },
  ];

  return (
    <>
      <div className="skills-outer-container" id="skills">
        <h1 className="header">Skillset</h1>
        <div className="skills-container">
          {skills.map((skill, index) => (
            <div key={index} className="skill-box">
              <h3>{skill.title}</h3>
              <div>{skill.description}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Skills;
