import React from "react";
import "./Projects.css"; // Import your CSS file
// Import your video file

function Projects() {
  const projects = [
    {
      title: "Portfolio Website",
      description:
        "The website that you are looking at right now. Created using React.js and uploaded on netlify. The design is minimal and unique, incorporating videos loops.",
      link: "https://example.com/portfolio",
    },
    {
      title: "Only Cams",
      description:
        "A webstore for video assets like film grain, plugins, overlays, sfx and much more. Developed using MERN stack , data is stored on AWS and Cloudinary,Stripe(test) is used for payment",
      link: "https://example.com/project2",
    },
    {
      title: "StockSlinger",
      description:
        "A market companion that tracks the market,fetches real time data from internet about stocks and commodities shows various Indicators,graphs and trends.Shows relevent market news.Offers a personalised watchlist for user.",
      link: "https://example.com/project3",
    },
  ];

  return (
    <div className="outerproj" id="projects">
      <div className="projects-container">
        <h1 className="myproj">Projects</h1>
        <div className="projects-grid">
          {projects.map((project, index) => (
            <div key={index} className="project-box">
              <h2>{project.title}</h2>
              <p>{project.description}</p>
              <a href={project.link} target="_blank" rel="noopener noreferrer">
                <button className="buttonp">Visit Project</button>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Projects;
